import React from "react";

function OurServices() {
  return (
    <>
      <section id="ourservices">
        <div className="ourservices container">
          <div className="container--py">
            <h2>Our Services</h2>
            <div className="ourservices__grid">
              <div className="ourservices__item">
                <div className="ourservices__title">
                  Direct Hire Recruitment
                </div>
                <div className="ourservices__description">
                  ATS's Direct Hire Division will find the right candidate to
                  advance your business and become a valuable long-term asset to
                  you.
                </div>
              </div>

              <div className="ourservices__item">
                <div className="ourservices__title">Contract Recruitment </div>
                <div className="ourservices__description">
                  Our dedicated & knowledgeable Contract Division has access to
                  some of the most talented contractors in the market, having
                  often completed multiple assignments through us that can help
                  you plug a skill gap or instill much needed expertise into a
                  project. With full compliance knowledge of IR35, AWR, etc. & a
                  sophisticated back-office, it's assured that your contractors
                  on site are fully focused on helping your business.
                </div>
              </div>

              <div className="ourservices__item">
                <div className="ourservices__title">
                  Retained/Executive Recruitment
                </div>
                <div className="ourservices__description">
                  Our proactive, professional and discreet Retained/Executive
                  Recruitment Team will utilize advanced techniques to identify
                  and engage the perfect senior, executive, or highly
                  specialized appointment for any key hire.
                </div>
              </div>

              <div className="ourservices__item">
                <div className="ourservices__title">
                  Recruitment Process Outsourcing
                </div>
                <div className="ourservices__description">
                  ATS's RPO solution has been designed to remove the time, costs
                  and hassle of managing multiple recruitment suppliers from
                  your team as an embedded extension of your brand. Taking the
                  whole process away and simply get results.
                </div>
              </div>

              <div className="ourservices__item">
                <div className="ourservices__title">Payroll Services</div>
                <div className="ourservices__description">
                  ATS Provides compliant and flexible payroll solutions designed
                  to deliver you significant savings in back office
                  administration costs and time.
                </div>
              </div>
              <div className="ourservices__item">
                <div className="ourservices__title">Project Recruitment</div>
                <div className="ourservices__description">
                  Utilizing economies of scale and one message to market, ATS's
                  Project Recruitment Services alleviates the stress from a
                  multiple hire campaign as you are building a full team.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default OurServices;
