import React from "react";
import ContactForm from "../../Elements/Form/ContactForm";

function Contact() {
  return (
    <>
      <section id="contact" className="contact">
        <div className="contact__content container container--pall">
          <div className="contact__intro">
            <h2>Contact Us</h2>
            <div className="contact__info col1">
              <ContactForm />
            </div>
          </div>
          <div className="contact__info col2">
            <div className="contact">Phone: 281-299-1831</div>

            <div className="contact">
              Address: Addison Transfer Solutions, LLC 7110 Plantation Dr
              Baytown, TX 77523
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Contact;
