import React from "react-dom/client";
function JobPost() {
  return (
    <>
      <div id="career">
        <br />
        <br />
        <br />
        <br />
      </div>
      <section>
        <div className="article__content container container--pall">
          <h2>Recent Jobs</h2>

          <iframe
            title="Jobs"
            src="https://evoportalus.tracker-rms.com/Opportunity/Lite/?db=ATSRCMT"
          ></iframe>
        </div>
      </section>
    </>
  );
}

export default JobPost;
