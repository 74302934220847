import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";

import Home from "./components/Home/Home";
import Team from "./components/Team/Team";
import Notfound from "./components/NotFound/Notfound";
import OurServices from "./components/OurServices/OurServices";

const app = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<Notfound />} />
        <Route path="/" element={<Home />} />
        <Route path="/team" element={<Team />} />
        <Route path="/ourservices" element={<OurServices />} />
      </Routes>
    </BrowserRouter>
  );
};

export default app;
