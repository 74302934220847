import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";

import "font-awesome/css/font-awesome.min.css";
function Nav() {
  const myFunction = () => {
    var x = document.getElementById("myTopnav");
    if (x.className === "topnav") {
      x.className += " responsive";
    } else {
      x.className = "topnav";
    }
  };
  return (
    <>
      <div className="header">
        <div className="topnav" id="myTopnav">
          <div className="topnav__menu">
            <div className="topnav__logo">
              <a href="/">
                <img src="./logo.png" alt="Addison Transfer Solutions" />
              </a>
            </div>

            <div className="topnav__links">
              <a href="/">Home</a>
              <AnchorLink href="#about">About</AnchorLink>
              <AnchorLink href="#services">Solutions</AnchorLink>
              <AnchorLink href="#ourservices">Services</AnchorLink>
              <AnchorLink href="#career">Career</AnchorLink>
              <AnchorLink href="#contact">Contact</AnchorLink>
              <a href="/#" className="icon" onClick={myFunction}>
                <i className="fa fa-bars"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Nav;
