import React from "react";

function Solutions() {
  return (
    <>
      <section id="services" className="articles">
        <div className="article__content container container--pall">
          <h2>Our Services</h2>

          <div className="article__grid">
            <a href="/" className="article__item">
              <div
                className="article__image"
                style={{
                  backgroundImage: "url('../assets/images/image-currency.jpg')",
                }}
              ></div>
              <div className="article__text">
                {/* <div className="article__author">By Claire Robinson</div> */}
                <div className="article__title">Direct Hire</div>
                <div className="article__description">
                  ATS sources top talent for hard-to-fill, key positions across
                  a broad range of business disciplines.
                </div>
              </div>
            </a>

            <a href="/" className="article__item">
              <div
                className="article__image"
                style={{
                  backgroundImage:
                    "url('../assets/images/image-restaurant.jpg')",
                }}
              ></div>
              <div className="article__text">
                {/* <div className="article__author">By Wilson Hutton</div> */}
                <div className="article__title">Contract Consulting</div>
                <div className="article__description">
                  Our extensive candidate network allows us to identity proven
                  performers who can step in and make an immediate contribution.
                </div>
              </div>
            </a>

            <a href="/" className="article__item">
              <div
                className="article__image"
                style={{
                  backgroundImage: "url('../assets/images/image-plane.jpg')",
                }}
              ></div>
              <div className="article__text">
                {/* <div className="article__author">By Wilson Hutton</div> */}
                <div className="article__title">Temporary Employment</div>
                <div className="article__description">
                  Whether we are providing you with one temporary for a day or
                  fifty temporaries for a long-term assignment, every candidate
                  is guaranteed.
                </div>
              </div>
            </a>

            <a href="/" className="article__item">
              <div
                className="article__image"
                style={{
                  backgroundImage: "url('../assets/images/image-confetti.jpg')",
                }}
              ></div>
              <div className="article__text">
                {/* <div className="article__author">By Claire Robinson</div> */}
                <div className="article__title">Temp to Hire</div>
                <div className="article__description">
                  A clear plan combined with a professional staffing agency like
                  ATS, ensures your business will find the talent
                </div>
              </div>
            </a>
          </div>
        </div>
      </section>
    </>
  );
}

export default Solutions;
