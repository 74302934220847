import React from "react";

function Hero() {
  return (
    <>
      <section className="hero">
        <div className="container">
          <div className="hero__image"></div>

          <div className="hero__text container--pall">
            <h1>Sourcing Qualified Candidates!</h1>
            <p>
              ATS Recruitment is a specialist provider of Engineering and
              Technical talent.
            </p>
            <a href="#career" className="button hero__cta">
              Job Search
            </a>
          </div>
        </div>
      </section>
    </>
  );
}

export default Hero;
