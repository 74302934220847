import React from "react";
import Nav from "../../Elements/Nav/Nav";

function Header() {
  return (
    <>
      <header className="header">
        <Nav />
      </header>
    </>
  );
}

export default Header;
