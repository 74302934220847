import React from "react";

function Notfound() {
  return (
    <div>
      <h1>This is not a good path!</h1>
    </div>
  );
}

export default Notfound;
