import React from "react";

function AreasOfExpertise() {
  return (
    <>
      <section id="areasofexpertise" className="aoe">
        <div className="aoe__content container container--pall">
          <div className="aoe__intro">
            <h2>Areas of Expertise</h2>
          </div>

          <div className="aoe__grid">
            <div className="aoe__item">
              <div className="aoe__title">Licensed Technology</div>
            </div>

            <div className="aoe__item">
              <div className="aoe__title">Automation & Controls </div>
            </div>

            <div className="aoe__item">
              <div className="aoe__title">Power & Distribution</div>
            </div>

            <div className="aoe__item">
              <div className="aoe__title">Finance & Accounting </div>
            </div>

            <div className="aoe__item">
              <div className="aoe__title">
                Software Development / IT (Government and Civilian)
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AreasOfExpertise;
