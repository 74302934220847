import React from "react";

function Footer() {
  return (
    <>
      <footer className="footer">
        <div className="container">
          <a className="footer__logo" href="/">
            <img src="../logo-dark.png" alt="Addison Transfer Solutions" />
          </a>

          <div className="footer__social">
            <a href="https://www.linkedin.com/company/addison-transfer-solutions/about/">
              <img
                src="../assets/images/linkedin-svgrepo-com-2.svg"
                alt="linked"
                width={24}
                height={24}
              />
            </a>
          </div>

          <div className="footer__links col1">
            {/* <a href="/About">About Us</a>
            <a href="/Contact">Contact</a>
            <a href="/Services">Services</a>
            <a href="Career">Careers</a> */}
            {/* <div className="footer__phone">Phone: 281-299-1831</div> */}
          </div>

          <div className="footer__links col2">
            {/* <div className="footer__address">
              Address: Addison Transfer Solutions, LLC 7110 Plantation Dr
              Baytown, TX 77523
            </div> */}
          </div>

          <div className="footer__cta"></div>

          <div className="footer__copyright">
            &copy; Addison Transfer Solutions. All Rights Reserved.
          </div>
        </div>
        <div className="attribution">
          Designed By .<a href="http://nerxmedia.com">Nerx Media</a>.
        </div>
      </footer>
    </>
  );
}

export default Footer;
