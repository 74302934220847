import React from "react";
import Mission from "../../Mission/Mission";

function About() {
  return (
    <>
      <Mission />

      <section id="about" className="about">
        <div className="about__content container container--pall">
          <div className="about__intro">
            <h2>Why Choose ATS Recruitment?</h2>
            <p>
              ATS Recruitment is a specialist provider of Engineering and
              Technical talent to the United States petrochemical/refining
              industry with a growing service offering in the Power &
              Renewables, Life Sciences, Construction & Infrastructure, and
              Manufacturing industries.
            </p>
            <p>
              ATS Recruitment helps businesses source qualified candidates so
              they can spend more time focusing on completing successful
              projects and less time worrying about their staffing needs. ATS
              Recruitment provides a full range of staffing solutions, including
              Contract, Direct, and Project Hire; Payroll & Transition Services;
              and innovative solutions.
            </p>

            <p>
              <a href="/team">
                <div
                  className="article__team"
                  style={{
                    backgroundImage: "url('../assets/images/ats-team.png')",
                  }}
                ></div>
              </a>
            </p>
          </div>

          <div className="about__grid">
            {/* <div className="about__item">
              <div className="about__title">Direct Hire</div>
              <div className="about__description">
                ATS sources top talent for hard-to-fill, key positions across a
                broad range of business disciplines.
              </div>
            </div>

            <div className="about__item">
              <div className="about__title">Contract Consulting</div>
              <div className="about__description">
                Our extensive candidate network allows us to identity proven
                performers who can step in and make an immediate contribution .
              </div>
            </div>

            <div className="about__item">
              <div className="about__title">Temporary Employment</div>
              <div className="about__description">
                Whether we are providing you with one temporary for a day or
                fifty temporaries for a long-term assignment, every candidate is
                guaranteed.
              </div>
            </div>

            <div className="about__item">
              <div className="about__title">Temp to Hire</div>
              <div className="about__description">
                Manage your savings, investments, pension, and much more from
                one account. Tracking your money has never been easier.
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
}

export default About;
