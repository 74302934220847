import React from "react";
import ReactShowMoreText from "react-show-more-text";
import SecNav from "../Elements/SecNav/SecNav";
import Footer from "../Home/Footer/Footer";

function Team() {
  return (
    <>
      <header className="header">
        <SecNav />
      </header>{" "}
      <section id="services" className="articles">
        <div className="article__content container container--pall">
          <h2>Our Team</h2>

          <div className="article__grid">
            <a href="/" className="article__item">
              <div
                className="article__image"
                style={{
                  backgroundImage: "url('../assets/images/image-jon.png')",
                }}
              ></div>
              <div className="article__text">
                <div className="article__author">Owner and CEO</div>
                <div>
                  <a href="https://www.linkedin.com/in/jon-addison-10a63b220/">
                    <img
                      src="../assets/images/linkedin-svgrepo-com.svg"
                      alt="linked"
                      width={15}
                      height={15}
                    />
                  </a>
                </div>
                <div className="article__title">Jon Addison </div>
                <div className="article__description">
                  <ReactShowMoreText
                    /* Default options */
                    lines={3}
                    more="Show more"
                    less="Show less"
                    className="content-css"
                    anchorClass="show-more-less-clickable"
                    expanded={false}
                    truncatedEndingComponent={"... "}
                  >
                    Jon Addison is an experienced Engineering and Technical
                    Recruiter with a passion for finding the right fit for both
                    job seekers and employers. In his professional life, he
                    excels at identifying top talent in the industry and
                    connecting them with companies that align with their skills
                    and career goals.
                    <br />
                    <br />
                    Outside of work, Jon is an avid runner and endurance
                    athlete. He regularly competes in ultra marathons, pushing
                    himself to the limit and testing his physical and mental
                    endurance. He also enjoys spending time outdoors, whether
                    it's hiking in the mountains or exploring new trails on foot
                    or bike.
                    <br />
                    <br />
                    In addition to his professional and athletic pursuits, Jon
                    is a devoted father of five and husband. He values the
                    importance of balancing his work and personal life, and
                    takes pride in being a role model for his children and
                    supporting his family.
                    <br />
                    <br />
                    With his experience in recruitment and his love for the
                    outdoors, Jon brings a unique perspective to his work. He
                    understands the importance of not only finding the right
                    candidate, but also ensuring they are a good fit for the
                    company culture and team dynamics.
                    <br />
                    <br />
                    Overall, Jon is a dedicated professional and skilled
                    recruiter, who is committed to helping both job seekers and
                    employers find the perfect match and achieving their goals.
                    He is a highly motivated and driven individual who is always
                    looking for new challenges and opportunities to grow and
                    improve, all while being a family man.
                  </ReactShowMoreText>
                </div>
              </div>
            </a>

            <a href="/" className="article__item">
              <div
                className="article__image"
                style={{
                  backgroundImage: "url('../assets/images/image-jason.png')",
                }}
              ></div>
              <div className="article__text">
                <div className="article__author">Director of Recruitment</div>
                <div>
                  <a href="https://www.linkedin.com/in/jason-zamorano-55964330/">
                    <img
                      src="../assets/images/linkedin-svgrepo-com.svg"
                      alt="linked"
                      width={15}
                      height={15}
                    />
                  </a>
                </div>
                <div className="article__title">Jason Zamorano</div>
                <div className="article__description">
                  <ReactShowMoreText
                    /* Default options */
                    lines={3}
                    more="Show more"
                    less="Show less"
                    className="content-css"
                    anchorClass="show-more-less-clickable"
                    expanded={false}
                    truncatedEndingComponent={"... "}
                  >
                    Jason Zamorano is a world class communicator that prides
                    himself in his passion for family, friends and the art of
                    conversation. His recruiting success is rooted in a keen
                    ability to listen and identify motivation. <br /> <br />
                    Through following a thorough process and dedication to both
                    client and candidates he quickly can add value to your
                    search. As a manager, Jason leads by example and loves a
                    challenging conversation or the opportunity to grow with his
                    teammates. Jason also has an innate ability to ask those
                    “hard questions” with grace and is known to quickly build
                    trust in complete strangers.
                  </ReactShowMoreText>
                </div>
              </div>
            </a>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Team;
