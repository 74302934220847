import React from "react";

function Mission() {
  return (
    <>
      <div className="mission__content container container--pall">
        <div className="mission__intro">
          <h2>Our Mission</h2>
          <p>
            "ATS Recruitment is dedicated to providing unparalleled recruitment
            services to the licensed technology engineering, manufacturing, EPC,
            software development, programming, power & distribution, finance and
            accounting industries. We are committed to sourcing, attracting and
            retaining the most talented and diverse professionals in the field,
            and connecting them with the most reputable organizations. Our goal
            is to drive innovation and excellence in these industries, and to be
            the premier recruitment agency of choice."
          </p>
        </div>
      </div>
    </>
  );
}

export default Mission;
