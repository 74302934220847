import React from "react";
import JobPost from "../Jobs/Jobs";
import OurServices from "../OurServices/OurServices";
import About from "./About/About";
import AreasOfExpertise from "./AreasOfExpertise/AreasOfExpertise";
import Contact from "./Contact/Contact";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import Hero from "./Hero/Hero";
import Solutions from "./Solutions/Solutions";

function Home() {
  return (
    <>
      <Header />
      <Hero />
      <About />
      <Solutions />
      <AreasOfExpertise />
      <OurServices />
      <JobPost />
      <Contact />
      <Footer />
    </>
  );
}

export default Home;
